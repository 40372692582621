import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import rewardsABI from 'config/abi/rewardContract.json'
import multicall from 'utils/multicall'
import farmsConfig from 'config/constants/farms'
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import contracts from "../../config/constants/contracts";


export const fetchFarmUserAllowances = async (account: string) => {

  const masterchef = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'allowance',
      params: [account, getMasterChefAddress()],
    }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance.balance._hex).toJSON()
  })
  return parsedTokenBalances
}



export const fetchFarmUserStakedBalances = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()

  const calls = farmsConfig.map((farm) => {
    return {
      address: masterChefAdress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(masterchefABI, calls)
  const parsedEarnings = rawEarnings.map((staked) => {
    return new BigNumber(staked[0]._hex).toJSON()
  })
  return parsedEarnings
}

export const fetchFarmUserEarnings = async (account: string) => {
  const masterChefAdress = getAddress(contracts.masterChef)

  const calls = farmsConfig.map((farm) => {
    return {
      address: masterChefAdress,
      name: 'pendingYobi',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(masterchefABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}

export const fetchFarmUserStakedBalancesOnCake = async () => {
  const calls = farmsConfig.map((farm) => {
    return {
      address: farm.endMasterChefAddress,
      name: 'userInfo',
      params: [farm.pidOfEndMasterChefAddress, farm.stratAddress],
    }
  })

  const rawStakedBalances = await multicall(masterchefABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchOurPoolContractBalances = async () => {
  const calls = farmsConfig.map((farm) => {
    return {
      address: getMasterChefAddress(),
      name: 'poolInfo',
      params: [farm.pid],
    }
  })

  const rawTokenBalances = await multicall(masterchefABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((poolInfo) => {
    return new BigNumber(poolInfo.lpSupply._hex).toJSON()
  })
  return parsedTokenBalances
}


export const fetchFarmEndMasterChefAddressInLpContractAddress = async () => {
  const calls = farmsConfig.map((farm) => {
    const masterChefAddress = farm.endMasterChefAddress
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [masterChefAddress],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmLPTokenInValue = async () => {
  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    const valueToken = getAddress(farm.quoteTokenAdresses)
    return {
      address: valueToken,
      name: 'balanceOf',
      params: [lpContractAddress],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchTotalLPSupply = async () => {
  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'totalSupply',
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}