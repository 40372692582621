import React from 'react';
import SVG from 'react-inlinesvg';
import numeral from 'numeral';
import styled from 'styled-components';
import arrowDown from 'assets/farms/down-arrow.svg';
import {IFarmCardHeaderProps} from '../types';
import {getBalanceNumber} from '../../../utils/formatBalance';
import Spacer from '../../../components/Spacer';
import {getAddress} from "../../../utils/addressHelpers";

const Block = styled.div`
  min-height: 85px;
  display: grid;
  grid-template-columns: 58px 1fr auto;
  column-gap: ${({theme}) => theme.space[1]};
  align-items: center;
  grid-auto-flow: column;
  cursor: pointer;
  transition: opacity ${({theme}) => theme.transition};

  :hover {
    opacity: ${({theme}) => theme.opacity};
  }
`;

const Image = styled.img`
  width: 32px;
  height: auto;
  border-radius: 10px;
`;

const Main = styled.div`
  display: flex;
  //grid-template-columns: repeat(2, 1fr);
    // column-gap: ${({theme}) => theme.space[3]};
  justify-content: space-between;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: ${({theme}) => theme.space[1]};
    padding: 16px 0 16px 0;
  }
`;

const Row = styled.div`
  align-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Column = styled.div<{ lg?: boolean }>`
  display: grid;
`;

const Cell = styled.div`
  color: ${({theme}) => theme.colors.light};
  font-weight: 700;
  font-size: ${({theme}) => theme.fontSizes[1]};
`;

const Name = styled(Cell)`
  text-transform: uppercase;
  font-size: ${({theme}) => theme.fontSizes[3]};
  color: ${({theme}) => theme.colors.primary};

  @media (max-width: 768px) {
    font-size: ${({theme}) => theme.fontSizes[2]};
  }
`;

const Tvl = styled(Cell)`
  color: ${({theme}) => theme.colors.secondary};
  font-size: ${({theme}) => theme.fontSizes[2]};
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: ${({theme}) => theme.fontSizes[1]};
  }
`;
const MaxTvl = styled(Cell)`
  color: ${({theme}) => theme.colors.secondary};
  font-size: ${({theme}) => theme.fontSizes[1]};
  font-weight: 300;

  @media (max-width: 768px) {
    font-size: ${({theme}) => theme.fontSizes[0]};
  }
`;

const Apy = styled(Cell)`
  color: ${({theme}) => theme.colors.primary};
  text-align: right;
  font-size: 15px;
  font-weight: 400;
    // text-shadow: ${({theme}) => theme.textShadow} 25px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Value = styled(Cell)`
  text-align: right;
  color: ${({theme}) => theme.colors.primary};
  font-size: ${({theme}) => theme.fontSizes[2]};

  @media (max-width: 768px) {
    font-size: ${({theme}) => theme.fontSizes[1]};
  }
`;

const Arrow = styled.span<{ up: boolean }>`
  display: flex;

  svg {
    transform: ${({up}) => (up ? 'rotate(180deg)' : 'none')};
    width: 16px;
  }

  path {
    fill: ${({theme}) => theme.colors.primary} !important;
  }

  @media (max-width: 768px) {
    align-self: start;
    padding: 16px 0 0 0;
  }
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

const FarmCardHeader: React.FC<IFarmCardHeaderProps> = (props) => {
    const {toggleCard, isCardOpened, label, TVL, APY, DAILY, priceFromAmountByPoolId, stakedBalance, pid, farm} = props;

    return (
        <Block onClick={toggleCard}>
            <div>
                {farm.tokenSymbol === "YBC"
                    ? <Image style={{width: 40}} src='/images/farms/yobi.png'/>
                    : <>
                        <Image style={{marginBottom: 3}} src={`/images/tokens/${getAddress(farm.tokenAddresses)}.png`}/>
                        <Image src={`/images/tokens/${getAddress(farm.quoteTokenAdresses)}.png`}/>
                    </>
                    }
            </div>
            <Column lg style={{marginRight: 10}}>
                <Name>{label}</Name>
                <Tvl>TVL ${numeral(TVL).format('0.00a')}</Tvl>
            </Column>
            <Main>
                <Row>
                    <Column>
                        <FlexCenter>
                            <Apy style={{opacity: 0.6, marginRight: 10, textAlign: "left"}}> APY </Apy>
                            <Apy>{APY}%</Apy>
                        </FlexCenter>
                        <FlexCenter>
                            <Apy style={{opacity: 0.6, marginRight: 10, textAlign: "left"}}> DAILY </Apy>
                            <Apy>{DAILY}%</Apy>
                        </FlexCenter>
                    </Column>
                </Row>
            </Main>
            <Arrow up={isCardOpened}>
                <SVG src={arrowDown}/>
            </Arrow>
        </Block>
    );
};

export default FarmCardHeader;
