import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import farmsConfig from 'config/constants/farms'
import { abiResolver } from '../../utils/endMasterChefResolver'
import { BIG_TEN, BIG_TWO, BIG_ZERO, DEFAULT_TOKEN_DECIMAL } from '../../utils/formatBalance'

const fetchFarms = async () => {
    const data = await Promise.all(
        farmsConfig.map(async (farmConfig) => {
            const lpAdress = getAddress(farmConfig.lpAddresses)
            const calls = [
                // Balance of token in the LP contract
                {
                    address: getAddress(farmConfig.tokenAddresses),
                    name: 'balanceOf',
                    params: [lpAdress],
                },
                // Balance of quote token on LP contract
                {
                    address: getAddress(farmConfig.quoteTokenAdresses),
                    name: 'balanceOf',
                    params: [lpAdress],
                },
                // Balance of LP tokens in the master chef contract
                {
                    address: lpAdress,
                    name: 'balanceOf',
                    params: [getMasterChefAddress()],
                },
                // Total supply of LP tokens
                {
                    address: lpAdress,
                    name: 'totalSupply',
                },
                // Token decimals
                {
                    address: getAddress(farmConfig.tokenAddresses),
                    name: 'decimals',
                },
                // Quote token decimals
                {
                    address: getAddress(farmConfig.quoteTokenAdresses),
                    name: 'decimals',
                },
            ]

            const [
                tokenBalanceLP,
                quoteTokenBlanceLP,
                lpTokenBalanceMC,
                lpTotalSupply,
                tokenDecimals,
                quoteTokenDecimals,
            ] = await multicall(erc20, calls)

            // Ratio in % a LP tokens that are in staking, vs the total number in circulation
            const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

            // Total value in staking in quote token value
            const lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
                .div(DEFAULT_TOKEN_DECIMAL)
                .times(BIG_TWO)
                .times(lpTokenRatio)

            // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
            const tokenAmount = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals)).times(lpTokenRatio)
            const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
                .div(BIG_TEN.pow(quoteTokenDecimals))
                .times(lpTokenRatio)
            const [info, totalAllocPoint] = await multicall(masterchefABI, [
              {
                address: getMasterChefAddress(),
                name: 'poolInfo',
                params: [farmConfig.pid],
              },
              {
                address: getMasterChefAddress(),
                name: 'totalAllocPoint',
              },
            ])
            const allocPoint = new BigNumber(info.allocPoint._hex)
            const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))

            let endMasterChefPoolWeight = BIG_ZERO
            if (!farmConfig.isOurPool) {
                const [endMasterChefInfo, endMasterChefTotalAllocPoint] = await multicall(
                    abiResolver(farmConfig.endMasterChefTokenSymbol),
                    [
                        {
                            address: farmConfig.endMasterChefAddress,
                            name: 'poolInfo',
                            params: [farmConfig.pidOfEndMasterChefAddress],
                        },
                        {
                            address: farmConfig.endMasterChefAddress,
                            name: 'totalAllocPoint',
                        },
                    ],
                )
                const endMasterChefAllocPoint = new BigNumber(endMasterChefInfo.allocPoint._hex)
                endMasterChefPoolWeight = endMasterChefAllocPoint.div(new BigNumber(endMasterChefTotalAllocPoint))
            }

            return {
                ...farmConfig,
                poolWeight: poolWeight.toJSON(),
                tokenAmount: tokenAmount.toJSON(),
                quoteTokenAmount: quoteTokenAmount.toJSON(),
                lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
                tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toJSON(),
                endMasterChefPoolWeight: endMasterChefPoolWeight.toJSON(),
            }
        }),
    )
    return data
}

export default fetchFarms
