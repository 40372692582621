import React, {useCallback, useMemo, useState} from 'react';
import numeral from 'numeral';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';
import {useWeb3React} from '@web3-react/core';
import {IFarmCardProps} from '../types';
import FarmCardHeader from './FarmCardHeader';
import FarmCardDetailed from './FarmCardDetailed';
import usePrices from '../../../hooks/usePrices';
import amountInPoolResolver from '../../../utils/amountInPoolHelper';
import {BIG_ZERO, getBalanceNumber} from '../../../utils/formatBalance';
import {
    AUTO_PER_BLOCK,
    BASE_ADD_LIQUIDITY_URL, BASE_BSW_ADD_LIQUIDITY_URL,
    BISWAP_PER_BLOCK,
    BLOCKS_PER_YEAR,
    CAKE_PER_BLOCK
} from '../../../config';
import getPriceFromAmountByPoolId from '../../../utils/getPriceFromAmountByPoolId';
import getLiquidityUrlPathParts from '../../../utils/getLiquidityUrlPathParts';
import {getAddress} from '../../../utils/addressHelpers';
import lpAprs from '../../../config/constants/lpAprs.json';
import contracts from "../../../config/constants/contracts";

const Block = styled.div`
  border-radius: ${({theme}) => theme.borderRadius};
  padding: 0 ${({theme}) => theme.space[2]};
  background: rgb(30, 32, 48);
  width: 800px;

  @media (max-width: 768px) {
    width: 390px;
  }
`;

const FarmCard: React.FC<IFarmCardProps> = (props) => {
    const {farm} = props;

    const [isCardOpened, setCardOpened] = useState(false);
    const toggleCard = useCallback(() => setCardOpened(!isCardOpened), [isCardOpened]);

    const {account} = useWeb3React();
    const prices = usePrices();
    const ourTokenPrice = prices.ourToken.toNumber();
    const cakePrice = prices.cake.toNumber();
    const biswapPrice = prices.biswap.toNumber();
    const doubleTokenPrice = farm.endMasterChefTokenSymbol === 'CAKE' ? cakePrice : biswapPrice;

    const amountInPool = amountInPoolResolver(farm);
    const amountInEndPool = farm && farm.userData && farm.userData.endMasterChefInLp || BIG_ZERO;
    const priceFromAmountByPoolId = useMemo(() => getPriceFromAmountByPoolId(farm, prices), [farm, prices]);

    const cakeRewardPerBlock = AUTO_PER_BLOCK.times(farm.poolWeight);
    const cakeRewardPerYear = cakeRewardPerBlock.times(BLOCKS_PER_YEAR);

    const tokenBalance = new BigNumber(farm && farm.userData && farm.userData.tokenBalance || 0);
    const stakedBalance = new BigNumber(farm && farm.userData && farm.userData.stakedBalance || 0);

    const stakedTotalUsd = getBalanceNumber(amountInPool) * (priceFromAmountByPoolId);
    const aprBonus = lpAprs[getAddress(farm.lpAddresses)?.toLocaleLowerCase()] ?? 0;
    const reinvestApyTimes = 3650;

    const APR = useMemo(
        () =>
            numeral(cakeRewardPerYear.times(new BigNumber(
                (100 * ourTokenPrice) / stakedTotalUsd))
                .toNumber(),
            ).format('0.0a'),
        [cakeRewardPerYear, ourTokenPrice, stakedTotalUsd],
    );

    const DAILY = useMemo(
        () =>
            numeral(
                (cakeRewardPerYear
                    .times(new BigNumber((100 * ourTokenPrice) / stakedTotalUsd))
                    .toNumber())
                / 365,
            ).format('0.0a'),
        [cakeRewardPerYear, ourTokenPrice, stakedTotalUsd],
    );

    const earnings = farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO;
    const rawEarningsBalance = getBalanceNumber(earnings);
    const earningsInUsd = rawEarningsBalance * ourTokenPrice;

    const {quoteTokenSymbol, tokenAddresses, endMasterChefTokenSymbol, quoteTokenAdresses} = farm;
    const liquidityUrlPathParts = getLiquidityUrlPathParts({
        quoteTokenSymbol,
        tokenAddresses,
        endMasterChefTokenSymbol,
        quoteTokenAdresses
    });
    const addLiquidityUrl = farm.pid === 0 && `https://pancakeswap.finance/swap?inputCurrency=${getAddress(contracts.usdt)}&outputCurrency=${getAddress(farm.tokenAddresses)}`
        || `${BASE_ADD_LIQUIDITY_URL}/${liquidityUrlPathParts}`;
    const tvl = getBalanceNumber(amountInPool) * (priceFromAmountByPoolId);

    return (            
        <Block>
            <FarmCardHeader
                label={farm.lpSymbol}
                TVL={tvl}
                APY={APR}
                DAILY={DAILY}
                toggleCard={toggleCard}
                isCardOpened={isCardOpened}
                priceFromAmountByPoolId={priceFromAmountByPoolId}
                stakedBalance={stakedBalance}
                pid={farm.pid}
                farm={farm}
            />
            {isCardOpened && (
                <FarmCardDetailed
                    APY={APR}
                    DAILY={DAILY}
                    bscScan={farm.lpAddresses[process.env.REACT_APP_CHAIN_ID]}
                    stakedBalance={stakedBalance}
                    rawEarningsBalance={rawEarningsBalance}
                    account={account}
                    tokenBalance={tokenBalance}
                    priceFromAmountByPoolId={priceFromAmountByPoolId}
                    addLiquidityUrl={addLiquidityUrl}
                    farm={farm}
                    earningsInUsd={earningsInUsd}
                    tvl={tvl}
                />
            )}
        </Block>
    );
};

export default FarmCard;
