import React, {useState} from 'react';
import styled from 'styled-components';
import CardActionsContainer from 'components/FarmCard/CardActionsContainer';
import {IFarmCardDetailedProps} from '../types';
import truncate from '../../../utils/truncate';
import Button from '../../../components/Button/Button';
import {getBalanceNumber} from '../../../utils/formatBalance';
import {useHarvest, useHarvestWithdraw} from '../../../hooks/useHarvest';
import {ButtonAppearanceEnum} from '../../../components/Button/types';
import usePrices from '../../../hooks/usePrices';

const Block = styled.div`
  min-height: 200px;
  padding: ${({theme}) => theme.space[1]} 0;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const Column = styled.div`
  width: calc(50% - 10px);
  flex: 0 0 auto;
  display: grid;
  row-gap: ${({theme}) => theme.space[1]};
  grid-template-rows: repeat(5, 40px);
  grid-template-columns: 80px 1fr;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 10px;
  padding-left: 20px;

  :first-child {
    display: block;
    //border-right: 1px solid rgba(255, 255, 255, 0.3);
    margin-right: 10px;
    padding-left: 0;
    width: 50%;
  }

  @media (max-width: 768px) {
    :first-child {
      margin-top: 16px;
      border-right: 0;
      width: 100%;
    }

    width: 100%;
    row-gap: ${({theme}) => theme.space[0]};
  }
`;

const Earned = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: start;
  }
`;

const Wallet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const Balance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;

  & > span:first-child {
    margin-bottom: ${({theme}) => theme.space[1]};
    color: ${({theme}) => theme.colors.secondary};
    font-weight: 600;
    font-size: ${({theme}) => theme.fontSizes[2]};
  }

  & > span:last-child {
    color: ${({theme}) => theme.colors.primary};
    font-weight: 700;
    font-size: ${({theme}) => theme.fontSizes[5]};
  }
`;
const Head = styled.div`
  font-size: ${({theme}) => theme.fontSizes[1]};
  font-weight: 700;
  color: ${({theme}) => theme.colors.primary};
  opacity: 0.8;

  @media (max-width: 768px) {
    font-size: ${({theme}) => theme.fontSizes[0]};
  }
`;

const Value = styled.div`
  font-size: ${({theme}) => theme.fontSizes[2]};
  font-weight: 600;
  color: ${({theme}) => theme.colors.primary};

  @media (max-width: 768px) {
    font-size: ${({theme}) => theme.fontSizes[1]};
  }
`;

const Link = styled.a`
  color: ${({theme}) => theme.colors.secondary};
  font-size: ${({theme}) => theme.fontSizes[2]};
  font-weight: 700;
  text-decoration: underline;

  :hover {
    color: ${({theme}) => theme.colors.primary};
    text-decoration: underline;
  }
`;
const DistributionText = styled.p`
  text-align: center;
  color: rgba(255, 255, 255, 0.75);
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
`;
const FlexCenterRowToMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 12px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FarmCardDetailed: React.FC<IFarmCardDetailedProps> = ({
                                                                stakedBalance,
                                                                rawEarningsBalance,
                                                                account,
                                                                priceFromAmountByPoolId,
                                                                addLiquidityUrl,
                                                                farm,
                                                                earningsInUsd,
                                                                tvl
                                                            }) => {
    const [pendingTx, setPendingTx] = useState(false);
    const tokenName = farm.lpSymbol;
    const stakedBalanceString = `${getBalanceNumber(stakedBalance).toFixed(6)} ${tokenName}`;
    const {onReward} = useHarvest(farm.pid);
    const isFloky = farm.lpSymbol === "FLOKI-BNB LP"

    return (
        <>
            <Block>
                <Column>
                    <Wallet>
                        <Balance>
                            <CardActionsContainer
                                tvl={tvl}
                                farm={farm} account={account}
                                addLiquidityUrl={addLiquidityUrl}/>
                        </Balance>
                    </Wallet>
                </Column>
                <Column>
                    <Head>Staked</Head>
                    <Value>{stakedBalanceString}</Value>
                    <Head>Staked USD</Head>
                    <Value>{(getBalanceNumber(stakedBalance) * priceFromAmountByPoolId).toFixed(2)} USD</Value>
                    <Head>Earned</Head>
                        <Value>
                            {rawEarningsBalance.toFixed(3)} YobiCoin (${earningsInUsd.toFixed(3)})
                        </Value>
                    <Head>Earn</Head>
                    <Earned>
                        <Value style={{marginRight: 15}}>
                            YobiCoin {farm.doubleFarmActive && `+ ${farm.doubleName}`}</Value>
                        <Button
                            disabled={pendingTx}
                            appearance={ButtonAppearanceEnum.secondary}
                            onClick={async () => {
                                setPendingTx(true);
                                await onReward();
                                setPendingTx(false);
                            }}
                        >
                            Harvest
                        </Button>
                    </Earned>
                </Column>
            </Block>
        </>
    );
};

export default FarmCardDetailed;
