import React from 'react'
import styled from 'styled-components'
import { ButtonAppearanceEnum, IButtonProps } from './types'

const   Button = styled.button<IButtonProps>`
  display: inline-block;
  background: ${({ theme, appearance }) => theme.button[appearance].background};
  color: ${({ theme, appearance }) => theme.button[appearance].color};
  border-radius: 20px;
  padding: ${({ theme, appearance }) => theme.button[appearance].padding};
  font-size: ${({ theme, appearance }) => theme.button[appearance].fontSize};
  width: auto;
  border: ${({ theme, appearance }) => theme.button[appearance].border};
  border-top: ${({ theme, appearance }) => theme.button[appearance].borderTop};
  border-left: ${({ theme, appearance }) => theme.button[appearance].borderLeft};
  border-right: ${({ theme, appearance }) => theme.button[appearance].borderRight};
  border-bottom: ${({ theme, appearance }) => theme.button[appearance].borderBottom};

  font-weight: 700;
  cursor: pointer;
  transition: all ${({ theme }) => theme.transition};
  outline: none;

  :hover {
    font-size: ${({ theme, appearance }) => theme.button[appearance].fontSizeActive ? theme.button[appearance].fontSizeActive 
            : theme.button[appearance].fontSize};
    background: ${({ theme, appearance }) => theme.button[appearance].activeBackground
  ? theme.button[appearance].activeBackground
  : theme.button[appearance].background};
    color: ${({ theme, appearance }) => theme.button[appearance].activeColor
  ? theme.button[appearance].activeColor
  : theme.button[appearance].color};
    filter: ${({ appearance }) => (appearance === ButtonAppearanceEnum.gradient ? 'saturate(2)' : 'none')};
    border-top: ${({ theme, appearance }) => theme.button[appearance].activeBorderTop
  ? theme.button[appearance].activeBorderTop : theme.button[appearance].borderTop};
    border-bottom: ${({ theme, appearance }) => theme.button[appearance].activeBorderBottom
  ? theme.button[appearance].activeBorderBottom : theme.button[appearance].borderBottom };
    border-left: ${({ theme, appearance }) => theme.button[appearance].activeBorderLeft
  ? theme.button[appearance].activeBorderLeft : theme.button[appearance].borderLeft};
    border-right: ${({ theme, appearance }) => theme.button[appearance].activeBorderRight
  ? theme.button[appearance].activeBorderRight : theme.button[appearance].borderRight };
  }
`

const Link = styled(Button)`
  text-decoration: none;

  :hover {
    color: ${({ theme, appearance }) => theme.button[appearance].color};
  }
`

const CustomButton = (props: IButtonProps) => {
  const { url } = props
  const { target } = props

  if (url) {
    return <Link {...props} as='a' href={url} target={`${target || "_blank"}`} />
  }

  return <Button {...props} />
}

CustomButton.defaultProps = {
  appearance: ButtonAppearanceEnum.primary,
}

export default CustomButton
