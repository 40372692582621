import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {useWeb3React} from '@web3-react/core';
import {useDispatch} from 'react-redux';
import {useFarms} from '../../../state/hooks';
import useRefresh from '../../../hooks/useRefresh';
import {fetchFarmUserDataAsync} from '../../../state/farms';
import FarmCard from './FarmCard';
import usePrices from '../../../hooks/usePrices';
import Button from '../../../components/Button';
import {ButtonAppearanceEnum} from '../../../components/Button/types';
import {getBalanceNumber} from "../../../utils/formatBalance";
import {useMasterchef} from "../../../hooks/useContract";
import {harvest} from "../../../utils/callHelpers";

const Block = styled.div`
  display: grid;
  grid-auto-flow: row;
  row-gap: ${({theme}) => theme.space[1]};
  max-width: 800px;
  width: 100%;
  padding: ${({theme}) => theme.space[1]};
  justify-content: center;
  border-radius: 15px;
`;

const ButtonsBlock = styled.div`
  width: 800px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

interface ClickButtonProps {
    isActive?: boolean;
}

const ProjectSwapButtons = styled.p<ClickButtonProps>`
  cursor: pointer;
  color: #fff;
  border: ${(props) => (props.isActive ? '1px solid #ffc34e' : 'none')};
  opacity: ${(props) => (props.isActive ? 1 : 0.66)};
  border-radius: 8px;
  margin: 0 5px;
  padding: 0 16px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: rgba(255, 195, 78, 0.1);
  }
`;

const FlexCenterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const FarmsList: React.FC = () => {
    const farmsLP = useFarms();
    const {bnb: bnbPrice, ourToken: ourTokenPrice} = usePrices();
    const {account} = useWeb3React();

    const livePools = useMemo(() => farmsLP.filter((farm) => farm
    ), [farmsLP]);

    const activeFarms = livePools;
    const harvestAll = useMemo(() => farmsLP.filter((farm) => farm && farm.userData
        && farm.userData.earnings && getBalanceNumber(farm.userData.earnings) > 0
    ), [farmsLP]);
    const totalEarnings = harvestAll.length;
    const masterChefContract = useMasterchef();

    const [pendingTx, setPendingTx] = useState(false);
    const harvestAllFarms = useCallback(async () => {
        setPendingTx(true);
        // eslint-disable-next-line no-restricted-syntax
        for (let i = 0; i < harvestAll.length; i++) {
            try {
                // eslint-disable-next-line no-await-in-loop
                await harvest(masterChefContract, harvestAll[i].pid, account);
                console.log("success");
            } catch (error) {
                console.log(error);
            }
        }
        setPendingTx(false);
    }, [masterChefContract, account, harvestAll]);

    return (
        <Block>
            {activeFarms.map((farm) => (
                <FarmCard key={farm.pid} farm={farm} removed={false} account={account}/>
            ))}
        </Block>
    );
};

export default FarmsList;
