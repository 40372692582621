import contracts from './contracts';
import {FarmConfig, QuoteToken} from './types';
import {getAddress, getMasterChefAddress} from '../../utils/addressHelpers';

const farms: FarmConfig[] = [
    {
        pid: 0,
        lpSymbol: 'YBC',
        lpAddresses: {
            97: getAddress(contracts.cake),
            56: getAddress(contracts.cake),
        },
        tokenSymbol: 'YBC',
        tokenAddresses: {
            97: getAddress(contracts.cake),
            56: getAddress(contracts.cake),
        },
        quoteTokenSymbol: QuoteToken.BSW,
        quoteTokenAdresses: contracts.biswap,
        isOurPool: true,
        endMasterChefAddress: getMasterChefAddress(),
        pidOfEndMasterChefAddress: 0,
        endMasterChefTokenSymbol: "BISWAP",
        stratAddress: "0xD2085cA58557b88416B223A5dC2562E531f44aBd",
    },
    {
        pid: 1,
        lpSymbol: 'YBC-USDT LP',
        lpAddresses: {
            97: "0xc2713839CCf9d0d717Fce1596dF8A94892cEcEAF",
            56: "0xc2713839CCf9d0d717Fce1596dF8A94892cEcEAF",
        },
        tokenSymbol: 'YBC-USDT LP',
        tokenAddresses: {
            97: getAddress(contracts.cake),
            56: getAddress(contracts.cake),
        },
        quoteTokenSymbol: QuoteToken.USDT,
        quoteTokenAdresses: contracts.usdt,
        isOurPool: true,
        endMasterChefAddress: getMasterChefAddress(),
        pidOfEndMasterChefAddress: 0,
        endMasterChefTokenSymbol: "BISWAP",
        stratAddress: "0xD2085cA58557b88416B223A5dC2562E531f44aBd",
    },
];

export default farms;
