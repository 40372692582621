import React from 'react';
import styled from 'styled-components';
import {useBurnedBalance, useTotalSupply} from 'hooks/useTokenBalance';
import {useWeb3React} from "@web3-react/core";
import numeral from 'numeral';
import TVL from './views/TVL';
import FarmsList from './views/FarmsList';
import {getBalanceNumber} from '../../utils/formatBalance';
import usePrices from "../../hooks/usePrices";
import {getCakeAddress} from "../../utils/addressHelpers";
import LogoTextImg from "../../assets/logo_text.png"


const Title = styled.p`
  color: ${({theme}) => theme.colors.primary};
  font-weight: 600;
  margin: 0;
  padding: 0;
  font-size: 60px;
  margin-bottom: 6px;
  text-align: right;

  @media (max-width: 768px) {
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
`;

const MiniTitle = styled.p`
  color: ${({theme}) => theme.colors.primary};
  font-weight: 600;
  margin: 0;
  padding: 0;
  font-size: 30px;
  text-align: right;

  @media (max-width: 768px) {
    font-size: 24px;
    text-align: center;
    width: 100%;
  }
`;

const TitleNoAnimation = styled.p`
  color: white;
  font-weight: 600;
  padding: 0;
  font-size: 40px;
  text-align: left;
  width: 800px;
  margin-top: 50px;

  @media (max-width: 768px) {
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
`;

interface TitleProps {
    background?: boolean;
}

const MainPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 50px;
  max-width: 1400px;
  width: 100%;
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  max-width: 1400px;
  width: 100%;
  margin-top: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

`;

const StatisticsWrapper = styled.div`
  background: rgb(30, 32, 48);
  border-radius: 5px;
  width: 390px;
  padding: 10px;
  height: 140px;
  margin: 10px 8px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
`;

const StatisticsWrapperMini = styled.div`
  background: rgb(30, 32, 48);
  border-radius: 5px;
  width: 390px;
  padding: 10px;
  margin: 10px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: linear;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }

  h1 {
    font-size: 16px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.65);
    margin-left: 5px;
    margin-right: 5px;
  }

  p {
    font-size: 16px;
    color: #fff;
  }
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FlexCenterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const FlexCenterRowToMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageCoin = styled.img`
  width: 330px;
  margin-left: 40px;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 40px;
    width: 250px;
  }
`;


const MainText = styled.h1`
  font-size: 46px;
  margin-right: 10px;
  font-weight: 900;
  text-shadow: 0 8px 20px rgb(0 0 0 / 50%), 0 0 10px rgba(249, 172, 252, 0.5), 0 0 20px #f743cd;

  @media (max-width: 768px) {
    font-size: 26px;
  }
`
const Text = styled.h1`
  font-size: 24px;
  margin-right: 10px;
  font-weight: 300;
  text-shadow: 0 8px 20px rgb(0 0 0 / 50%), 0 0 10px rgb(252 229 172 / 50%), 0 0 20px #f7c443;
  max-width: 600px;
  text-align: center;

  @media(max-width: 768px) {
    font-size: 22px;
  }
`

const IndexPage: React.FC = () => {

    const {ourToken} = usePrices();
    const tokenSupply = getBalanceNumber(useTotalSupply());
    const burnedSupply = getBalanceNumber(useBurnedBalance(getCakeAddress()));

    const account = useWeb3React()

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <MainPageWrapper>
                {/* <MainText style={{color: "#f743b8"}}> YBC</MainText> */}
                <img src={LogoTextImg} alt="" style={{width: "33%"}}/>
                <Text> STAKING PLATFORM </Text>
                <TopWrapper>
                    <StatisticsWrapper>
                        <TVL/>
                    </StatisticsWrapper>
                    <div>
                        <StatisticsWrapperMini>
                            <h1> YobiCoin price</h1>
                            <FlexCenterRow>
                                <p> {ourToken.toNumber().toFixed(4)}</p>
                                <h1> USD </h1>
                            </FlexCenterRow>
                        </StatisticsWrapperMini>
                        <StatisticsWrapperMini>
                            <h1> YobiCoin burned</h1>
                            <FlexCenterRow>
                                <p> {numeral(burnedSupply).format('0.0a')}</p>
                                <h1> YobiCoin </h1>
                            </FlexCenterRow>
                        </StatisticsWrapperMini>
                        <StatisticsWrapperMini>
                            <h1> YobiCoin supply</h1>
                            <FlexCenterRow>
                                <p> {numeral(tokenSupply).format('0.0a')}</p>
                                <h1> YobiCoin </h1>
                            </FlexCenterRow>
                        </StatisticsWrapperMini>
                    </div>
                </TopWrapper>

                <FlexCenter>
                    <TitleNoAnimation> STAKING POOLS </TitleNoAnimation>
                </FlexCenter>
                <FarmsList/>
            </MainPageWrapper>
        </div>
    );
};

export default IndexPage;
