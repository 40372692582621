// Constructing the two forward-slash-separated parts of the 'Add Liquidity' URL
// Each part of the url represents a different side of the LP pair.
// In the URL, using the quote token 'BNB' is represented by 'ETH'
import {getAddress} from "./addressHelpers";

const getLiquidityUrlPathParts = ({ quoteTokenSymbol, tokenAddresses, endMasterChefTokenSymbol, quoteTokenAdresses}) => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  const firstPart = (quoteTokenSymbol === 'BNB' && endMasterChefTokenSymbol === "BISWAP") && 'ETH'
      || (quoteTokenSymbol === 'BNB' && endMasterChefTokenSymbol === "CAKE") && 'BNB' || getAddress(quoteTokenAdresses)
  const secondPart = tokenAddresses[chainId]
  return `${firstPart}/${secondPart}`
}

export default getLiquidityUrlPathParts
