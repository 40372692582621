import React, {useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import useUnstake from 'hooks/useUnstake';
import useStake from 'hooks/useStake';
import WithdrawModal from '../WithdrawModal';
import DepositModal from "../DepositModal";
import Spacer from '../Spacer';

interface FarmCardActionsProps {
    stakedBalance?: BigNumber;
    tokenBalance?: BigNumber;
    tokenName?: string;
    pid?: number;
    addLiquidityUrl?: string;
    isApproved?: boolean;
    lpContract?: any;
    userStableBalance?: any;
    userStableAllowance?: any;
    tvl: number;
    farm: any;
}

interface DepositWithdrawButtonsProps {
    depositModal?: boolean;
}

const DepositWithdrawButtons = styled.p<DepositWithdrawButtonsProps>`
  cursor: pointer;
  color: #fff;
  border: ${(props) => (props.depositModal ? '1px solid rgb(65, 190, 198)' : 'none')};
  opacity: ${(props) => (props.depositModal ? 1 : 0.66)};
  border-radius: 8px;
  padding: 5px 15px;
  width: 100%;
  text-align: center;
`;

const StakeAction: React.FC<FarmCardActionsProps> = ({
                                                         stakedBalance,
                                                         tokenBalance,
                                                         tokenName,
                                                         pid,
                                                         addLiquidityUrl,
                                                         isApproved,
                                                         lpContract,
                                                         farm,
                                                         userStableBalance,
                                                         userStableAllowance,
                                                         tvl
                                                     }) => {
    const [depositModal, setDepositModal] = useState(1);

    const [referralValue, setReferralValue] = useState(localStorage.getItem("referral"));
    const handleChangeRef = useCallback(
        (e: React.FormEvent<HTMLInputElement>) => {
            setReferralValue(e.currentTarget.value);
        },
        [setReferralValue],
    );

    const {onStake} = useStake(pid);
    const {onUnstake} = useUnstake(pid);

    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                <DepositWithdrawButtons onClick={() => setDepositModal(1)} depositModal={depositModal === 1}>
                    Deposit
                </DepositWithdrawButtons>
                <DepositWithdrawButtons onClick={() => setDepositModal(2)} depositModal={depositModal === 2}>
                    Withdraw
                </DepositWithdrawButtons>
            </div>
            <Spacer size="sm"/>
            {depositModal === 1 &&
            <DepositModal farm={farm} tvl={tvl} pid={pid} max={tokenBalance} onConfirm={onStake} tokenName={tokenName}
                          addLiquidityUrl={addLiquidityUrl} isApproved={isApproved} lpContract={lpContract}/>
            }
            {depositModal === 2 &&
            <WithdrawModal max={stakedBalance} onConfirm={onUnstake} tokenName={tokenName} pid={pid}/>}
            <Spacer size="md"/>
        </div>
    );
};

export default StakeAction;
