import BigNumber from 'bignumber.js';
import React, {useCallback, useMemo, useState} from 'react';
import {Text} from '@pancakeswap-libs/uikit';
import ModalInput from 'components/ModalInput';
import useI18n from 'hooks/useI18n';
import {getFullDisplayBalance} from 'utils/formatBalance';
import Button from './Button';
import {ButtonAppearanceEnum} from './Button/types';
import {useApprove} from '../hooks/useApprove';
import {getMasterChefAddress} from "../utils/addressHelpers";

interface DepositModalProps {
    max: BigNumber;
    onConfirm: (amount: string) => void;
    onDismiss?: () => void;
    tokenName?: string;
    addLiquidityUrl?: string;
    pid?: number;
    isApproved?: boolean;
    lpContract?: any;
    tvl: number;
    farm: any;
}

const DepositModal: React.FC<DepositModalProps> = ({
                                                       max,
                                                       onConfirm,
                                                       tokenName = '',
                                                       addLiquidityUrl,
                                                       pid,
                                                       isApproved,
                                                       lpContract,
                                                       farm,
                                                       tvl
                                                   }) => {

    const [val, setVal] = useState('');
    const [pendingTx, setPendingTx] = useState(false);
    const TranslateString = useI18n();
    const fullBalance = useMemo(() => {
        return getFullDisplayBalance(max);
    }, [max]);

    const handleChange = useCallback(
        (e: React.FormEvent<HTMLInputElement>) => {
            setVal(e.currentTarget.value);
        },
        [setVal],
    );

    const handleSelectMax = useCallback(() => {
        setVal(fullBalance);
    }, [fullBalance, setVal]);

    const [requestedApproval, setRequestedApproval] = useState(false);
    const {onApprove} = useApprove(lpContract, getMasterChefAddress());
    const handleApprove = useCallback(async () => {
        try {
            setRequestedApproval(true);
            await onApprove();
            setRequestedApproval(false);
        } catch (e) {
            console.error(e);
        }
    }, [onApprove]);

    return (
        <div>
            <ModalInput
                value={val}
                onSelectMax={handleSelectMax}
                onChange={handleChange}
                max={fullBalance}
                symbol={tokenName}
                addLiquidityUrl={addLiquidityUrl}
                inputTitle={TranslateString(1070, 'Wallet')}
                pid={pid}
            />
            <div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        marginTop: 15,
                    }}
                >
                    {isApproved &&
                    <Button
                        disabled={pendingTx || fullBalance === '0' || val === '0' || val === ''}
                        onClick={async () => {
                            setPendingTx(true);
                            await onConfirm(val);
                            setPendingTx(false);
                        }}
                        appearance={ButtonAppearanceEnum.primary}
                        style={{padding: '12px 20px', width: "100%"}}
                    >
                        {pendingTx ? TranslateString(488, 'Pending Confirmation') : TranslateString(464, 'Deposit')}
                    </Button>
                    }
                    {!isApproved && <Button
                        onClick={handleApprove}
                        disabled={requestedApproval}
                        appearance={ButtonAppearanceEnum.primary}
                        style={{padding: '12px 20px', width: "100%"}}
                    >
                        {requestedApproval ? 'Pending Approve' : 'Approve'}
                    </Button>
                    }
                </div>
            </div>
        </div>
    );
};

export default DepositModal;
