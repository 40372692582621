import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import logoImg from '../../assets/logo_text.png';
import {IHeaderProps} from './types';
import WalletButtonBase from './views/WalletButton';
import Certik from '../../assets/audit/certik.svg';
import Paladin from '../../assets/audit/paladin.svg';
import Shield from '../../assets/audit/shield.svg';

const Block = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 84px;
  margin: 0 auto;
  max-width: 1160px;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    padding: 0 16px 0 16px;
    flex-direction: column;
    height: 100%;
  }
`;

const Logo = styled.div`
  @media (max-width: 767.9px) {
    margin: 20px;
  }
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AuditsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 22px;
  @media (max-width: 768px) {
    //display: none;
  }
`;

const ConnectWalletWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Header = (props: IHeaderProps) => {

    return (
        <Block>
            <Logo>
                <Link to="/">
                    <img src={logoImg} alt="v" style={{height: 32}}/>
                </Link>
            </Logo>
            <ConnectWalletWrapper>
                <WalletButtonBase/>
            </ConnectWalletWrapper>
        </Block>
    );
};

export default Header;
