import cakeMasterChefAbi from 'config/abi/cakeMasterChef.json'
import biswapShefAbi from 'config/abi/biswapShef.json'
import {BISWAP_PER_BLOCK, CAKE_PER_BLOCK} from '../config';

export const abiResolver = (endMasterChefTokenSymbol: string) => {
  if (endMasterChefTokenSymbol && endMasterChefTokenSymbol === 'CAKE') {
    return cakeMasterChefAbi
  }
  if (endMasterChefTokenSymbol && endMasterChefTokenSymbol === 'BISWAP') {
    return biswapShefAbi
  }
  return null
}

export const rewardPerBlockResolver = (endMasterChefTokenSymbol: string) => {
  if (endMasterChefTokenSymbol && endMasterChefTokenSymbol === 'CAKE') {
    return CAKE_PER_BLOCK
  }
  if (endMasterChefTokenSymbol && endMasterChefTokenSymbol === 'BISWAP') {
    return BISWAP_PER_BLOCK
  }
  return null
}
