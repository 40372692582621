import React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import { IFooterProps } from './types'
import Button from '../Button/Button'
import { ISocialLink } from '../SocialLinks/types'
import discord from '../../assets/social-icons/discord.svg'
import telegram from '../../assets/social-icons/telegram.svg'
import twitter from '../../assets/social-icons/twitter.svg'
import reddit from '../../assets/social-icons/reddit.svg'
import facebook from '../../assets/social-icons/facebook.svg'
import vk from '../../assets/social-icons/vk.svg'

const Block = styled.footer`
  background: none;
  padding: 0 ${({ theme }) => theme.space[0]};
  margin-top: 20px;
`

const Nav = styled.nav`
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  flex-wrap: wrap;

  svg {
    width: 32px;
    height: 32px;
    background: none;
  }

  path {
    fill: #fff};
  }

  a {
    color: ${({ theme }) => theme.colors.secondary};

    :hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

const socialLinks: ISocialLink[] = [
  {
    id: 'telegram',
    icon: telegram,
    url: 'https://t.me/yobicoin',
  },
  {
    id: 'telegram',
    icon: telegram,
    url: 'https://t.me/yobicoinworld',
  },
  {
    id: 'facebook',
    icon: facebook,
    url: 'https://www.facebook.com/yobicoin',
  },
  // {
  //   id: 'vk',
  //   icon: vk,
  //   url: 'https://vk.com/yobicoin',
  // },
  {
    id: 'reddit',
    icon: reddit,
    url: 'https://www.reddit.com/r/yobicoin',
  },
  {
    id: 'twitter',
    icon: twitter,
    url: 'https://twitter.com/yobicoin',
  },
  {
    id: 'discord',
    icon: discord,
    url: 'https://discord.gg/KxugEV26',
  },
]

const Footer = (props: IFooterProps) => {

  return (
    <Block>
      <Nav>
        {socialLinks.map((link) => (
          <Button key={link.id} url={link.url}
                  style={{ margin: 10, background: "none", border: "none" }}>
            <SVG src={link.icon} />
          </Button>
        ))}
      </Nav>
    </Block>
  )
}

export default Footer
