import { provider as ProviderType } from 'web3-core'
import { Contract } from 'web3-eth-contract'
import { AbiItem } from 'web3-utils'
import erc20 from 'config/abi/erc20.json'
import NFT from 'config/abi/lotteryNft.json'
import univ2 from 'config/abi/uni_v2_lp.json'
import Web3 from 'web3'

export const getContract = (provider: ProviderType, address: string) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract((erc20 as unknown) as AbiItem, address)
  return contract
}

export const getContractNFT = (provider: ProviderType, address: string) => {
  const web3 = new Web3(provider)
  const contractNFT = new web3.eth.Contract((NFT as unknown) as AbiItem, address)
  return contractNFT
}

export const getLPContract = (provider: ProviderType, address: string) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract((univ2 as unknown) as AbiItem, address)
  return contract
}

export const getAllowance = async (
  lpContract: Contract,
  masterChefContract: Contract,
  account: string,
): Promise<string> => {
  try {
    const allowance: string = await lpContract.methods.allowance(account, masterChefContract.options.address).call()
    return allowance
  } catch (e) {
    return '0'
  }
}

export const getTokenBalance = async (
  provider: ProviderType,
  tokenAddress: string,
  userAddress: string,
): Promise<string> => {
  const contract = getContract(provider, tokenAddress)
  try {
    const balance: string = await contract.methods.balanceOf(userAddress).call()
    return balance
  } catch (e) {
    return '0'
  }
}

export const getItemsInfo = async (masterChefContract: Contract, itemId: number): Promise<any> => {
  try {
    const array: any = await masterChefContract.methods.itemsInfo(itemId).call()
    return array
  } catch (e) {
    return []
  }
}

export const getTokenURI = async (provider: ProviderType, tokenAddress: string, itemId: number): Promise<string> => {
  const contractNFT = await getContractNFT(provider, tokenAddress)
  try {
    const string: string = await contractNFT.methods.tokenURI(itemId).call()
    return string
  } catch (e) {
    return '0'
  }
}

export const ownerOfERC721 = async (provider: ProviderType, nftAddress: string, tokenId: string): Promise<string> => {
  const contract = getContractNFT(provider, nftAddress)
  try {
    const value: string = await contract.methods.ownerOf(tokenId).call()
    return value
  } catch (e) {
    return '0'
  }
}

export const balanceOfNft = async (provider: ProviderType, nftAddress: string, userAdress: string): Promise<string> => {
  const contract = getContractNFT(provider, nftAddress)
  try {
    const value: string = await contract.methods.balanceOf(userAdress).call()
    return value
  } catch (e) {
    return '0'
  }
}

export const tokenOfOwnerByIndex = async (provider: ProviderType, nftAddress: string, userAdress: string): Promise<string> => {
  const contract = getContractNFT(provider, nftAddress)
  try {
    const value: string = await contract.methods.tokenOfOwnerByIndex(userAdress, 0).call()
    return value
  } catch (e) {
    return '0'
  }
}

export const getLotteryList = async (provider: ProviderType, masterChefContract: Contract): Promise<any> => {
  try {
    const value: any = await masterChefContract.methods.getlotteryList().call()
    return value
  } catch (e) {
    return []
  }
}

export const getEarningsBalance = async (
  provider: ProviderType,
  masterChefContract: Contract,
  pid: number,
  userAddress: string,
): Promise<string> => {
  try {
    const balance: string = await masterChefContract.methods.pendingReward(pid, userAddress).call()
    return balance
  } catch (e) {
    return '0'
  }
}

export const getUserCakeInfo = async (
  provider: ProviderType,
  masterChefContract: Contract,
  pid: number,
  account: string,
): Promise<any> => {
  try {
    const balance: any = await masterChefContract.methods.userInfo(pid, account).call()
    return balance
  } catch (e) {
    return []
  }
}

export const getTokenFromPair0 = async (provider: ProviderType, tokenAddress: string): Promise<string> => {
  const contract = getLPContract(provider, tokenAddress)
  try {
    const token: string = await contract.methods.token0().call()
    return token
  } catch (e) {
    return null
  }
}

export const getTokenFromPair1 = async (provider: ProviderType, tokenAddress: string): Promise<string> => {
  const contract = getLPContract(provider, tokenAddress)
  try {
    const token: string = await contract.methods.token1().call()
    return token
  } catch (e) {
    return null
  }
}
