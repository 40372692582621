import React from 'react'
import BigNumber from 'bignumber.js'
import {
  usePriceBiswapBusd,
  usePriceBnbBusd,
  usePriceBtcBusd,
  usePriceCakeBusd,
  usePriceEthBusd,
  usePriceOurTokenBusd,
} from '../state/hooks';

export interface IPricesContext {
  bnb?: number
  eth?: number
  ourToken?: BigNumber
  cake?: BigNumber
  btc?: number
  biswap?: BigNumber
}

export const PricesContext = React.createContext<IPricesContext>({})

const PricesContextProvider = ({ children }) => {
  const bnb = usePriceBnbBusd()
  const eth = usePriceEthBusd()
  const btc = usePriceBtcBusd()
  const ourToken = usePriceOurTokenBusd()
  const cake = usePriceCakeBusd(bnb)
  const biswap = usePriceBiswapBusd(bnb)

  return <PricesContext.Provider value={{ bnb, eth, ourToken, cake, btc, biswap}}>{children}</PricesContext.Provider>
}

export default PricesContextProvider
