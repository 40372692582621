import {DefaultTheme} from 'styled-components';
import {ButtonAppearanceEnum} from '../components/Button/types';

const ThemeStyle: DefaultTheme = {
    fontSizes: ['10px', '12px', '14px', '16px', '18px', '20px'],
    space: ['10px', '16px', '24px', '32px', '48px', '64px'],
    colors: {
        layout: 'linear-gradient(359.19deg, rgb(32, 18, 62) -8.64%, rgb(17, 5, 32) 46.83%) rgb(15, 14, 26)',
        layout2: 'linear-gradient(359.19deg,rgb(18 34 62) -8.64%,rgb(5 11 32) 46.83%) rgb(15,14,26)',
        primary: 'rgb(255, 255, 255)',
        secondary: 'rgba(255, 255, 255, 0.7)',
        accent: 'rgb(65, 190, 198)',
        dark: 'rgb(0, 0, 0)',
        light: 'rgb(229, 229, 229)',
        footer: 'rgb(15, 14, 26)',
        catalog: 'rgb(15, 14, 26)',
    },
    button: {
        [ButtonAppearanceEnum.primary]: {
            color: '#fff',
            background: '#0024ff',
            fontSize: '13px',
            padding: '9px 23px',
            activeBackground: 'none',
            border: "2px solid #0024ff",
            activeColor: "#0024ff"
        },
        [ButtonAppearanceEnum.secondary]: {
            color: '#fff',
            background: "#3b4165",
            fontSize: '13px',
            padding: '9px 23px',
            activeBackground: 'none',
            border: "2px solid #3b4165",
            activeColor: "#3b4165"
        },
        [ButtonAppearanceEnum.gradient]: {
            color: 'rgb(255, 255, 255)',
            background: 'linear-gradient(286deg,#4CA0FC 23%,#07f69a 76%)',
            fontSize: '14px',
            padding: '8px 12px',
            border: "none",
            fontSizeActive: '15px'

        },
    },
    transition: 'ease 0.3s',
    borderRadius: '22px',
    textShadow: 'rgb(0 151 255) 0px 0px',
    opacity: '0.64',
};

export default ThemeStyle;
