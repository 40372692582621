import { createGlobalStyle } from 'styled-components'
import BgColor from "../assets/bg_color.png"

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.219;
  }

  html {
    font-size: ${({theme}) => theme.fontSizes[3]};
  }

  body {
    //background: rgb(17, 17, 32);
    //background: linear-gradient(120deg, rgb(0, 0, 0) 0%, #0e274d 70%);
    background: rgb(0, 0, 0);
    background-image: url(${BgColor});
    background-position: right bottom;
    background-repeat: no-repeat;
    color: ${({theme}) => theme.colors.primary};

    img {
      height: auto;
    }

    a {
      color: ${({theme}) => theme.colors.primary};
      font-size: ${({theme}) => theme.fontSizes[2]};
      font-weight: 700;
      transition: color ${({theme}) => theme.transition};
      text-decoration: none;

      :hover {
        color: ${({theme}) => theme.colors.accent};
      }
    }
  }
`

export default GlobalStyle
