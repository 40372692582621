import React from 'react'
import styled from 'styled-components'
import config from 'config/constants/wallets'
import WalletCard from './views/WalletCard'

const Block = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: 600;
  margin-bottom: 24px;
`

const Text = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.space[3]};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  text-align: center;
`

const WalletsList = styled.div`
  display: grid;
  row-gap: ${({ theme }) => theme.space[1]};
  width: 300px;
  
  @media(max-width: 768px) {
    width: 100%;
  }
`

const ConnectModal = ({ closeModal, login }) => {
  return (
    <Block>
      <Title>Connect Wallet</Title>
      <Text>Please connect your wallet with Binance Smart Chain</Text>
      <WalletsList>
        {config.map((wallet) => (
          <WalletCard key={wallet.title} closeModal={closeModal} login={login} {...wallet} />
        ))}
      </WalletsList>
    </Block>
  )
}

export default ConnectModal
