import { getBalanceNumber } from './formatBalance'
import { IPricesContext } from '../contexts/PricesContext'

const getPriceFromAmountByPoolId = (farm, prices: IPricesContext): number => {

  if (farm.pid === 0) {
     return prices.ourToken.toNumber()
  }

  if (!farm.userData) {
    return 0
  }

  if(farm.lpSymbol === 'CAKE' || farm.lpSymbol === 'CAKE OLD') {
    return prices.cake.toNumber()
  }

  const { userData, quoteTokenSymbol } = farm

  const amountValueByPoolId = getBalanceNumber(userData.lpTokenValue) / getBalanceNumber(userData.totalLPSupply)

  switch (quoteTokenSymbol) {
    case 'BUSD':
      return amountValueByPoolId * 2
    case 'USDC':
      return amountValueByPoolId * 2
    case 'USDT':
      return amountValueByPoolId * 2
    case 'BNB':
      return amountValueByPoolId * prices.bnb * 2
    case 'BSW':
      return amountValueByPoolId * prices.biswap.toNumber() * 2
    case 'MARS':
      return amountValueByPoolId * prices.ourToken.toNumber() * 2
    default:
      return amountValueByPoolId * prices.eth * 2
  }
}

export default getPriceFromAmountByPoolId
